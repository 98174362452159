<template>
  <div>
    <h1>Modulos</h1>
    <arq-crud
      :fields="data"
      :items="items"
    />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Module',
  data() {
    return {
      items: [
        {
          id: 3,
          title: 'test1',
          description: 'wat',
          icon: null,
          externalLink: null,
          targetBlank: false,
          sort: 0,
          status: true,
        },
        {
          id: 1,
          title: 'Consulta tu factura',
          description: 'Ingresa a Factura Web y consulta consumos e históricos',
          icon: 'ax-transaction-fast-image',
          externalLink:
            'https://portalfacturas-empresaspa.arqbs.com/InvoiceDowloadEpa/faces/index.xhtml',
          targetBlank: true,
          sort: 1,
          status: true,
        },
        {
          id: 2,
          title: 'Paga tu factura',
          description:
            'Ingresa a Factura Web. Paga y consulta consumos e históricos',
          icon: 'ax-transaction-fast-image',
          externalLink: 'https://www.abcpagos.com/recaudo_epa/index.php',
          targetBlank: true,
          sort: 2,
          status: true,
        },
      ],
      data: [
        {
          key: 'id',
          label: 'ID',
          type: 'number',
          sortable: 'true',
          noForm: true,
        },
        {
          key: 'title',
          label: 'Titulo',
          type: 'text',
          sortable: 'true',
        },
        {
          key: 'description',
          label: 'Descripción',
          type: 'text',
          sortable: 'true',
        },
        {
          key: 'status',
          label: 'Estado',
          type: 'boolean',
          sortable: 'true',
        },
        {
          key: 'icon',
          label: 'Imagen',
          type: 'text',
        },
        {
          key: 'externalLink',
          label: 'Link externo',
          type: 'text',
          sortable: 'true',
        },
        {
          key: 'sort',
          label: 'Orden',
          type: 'number',
          sortable: 'true',
        },
        { key: 'actions', noForm: true, label: 'Actions' },
      ],
      listModule: [],
    }
  },
  created() {
    //  this.getData()
  },
  methods: {
    getData() {
      axios
        .get('/api/module/all', {
          headers: {
            Authorization:
              'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpNWJ4cHVMZzMteEM1N1BSTm9WR0tvRGtiZEFYREtYcG5NTXotMlV5S1FRIn0.eyJleHAiOjE2MjcyNTMwNDMsImlhdCI6MTYyNzI1Mjc0MywianRpIjoiZmIxOTA0ZWItNTAyYS00ZDE0LTkzMGItOGY1MWJmNGQ2YTU3IiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MTgwL2F1dGgvcmVhbG1zL2hhYmJpbC1kZXYiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNmJlMWExOGQtNDdmYy00M2ZmLWI2YWMtNjhhMGNlM2IxMzE0IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiZXBhLWNsaWVudCIsInNlc3Npb25fc3RhdGUiOiJhMjFlZWNhMy03NjQyLTQ1OWEtYjU5ZC0wMmRmNDkyNWIxZjMiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJKaG9uIEppbWVuZXoiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJqaG9uZ2oiLCJnaXZlbl9uYW1lIjoiSmhvbiIsImZhbWlseV9uYW1lIjoiSmltZW5leiIsImVtYWlsIjoiamhvbmdqQGFycXVpdGVjc29mdC5jb20ifQ.W1petfvq3itJxne_utFQu9XQkfYyOpgDBXTxb626qF8_6c6au_rs6mi6BN3wwTV1BEq2eRcevA8LIGvRcvS0rlvYov0glY5gDNK24NevA0vTfozyMFzqcv5yvj0O3RoB4HstUmX4D4SdtLuJo5y7rJ9GQqdroRKnDHYPbjkW-8yRRxiiVb-IlQ6_qKzU7sLwTFU6Wwsc_lCIYzK-KJXUg5bk5nUsa98Ddprv_frXo17h4oayTGJ1OzhpEYrCYjoQzIMrQvY3DuprJFmEu6y2YwdoggeZ5K8LnfsHBOGmeF7tKIWWOzAnCPflahOFNF5MFPw1_ZrwgUHOc0lMvsFrYg',
          },
        })
        .then(res => {
          this.items = res.data.list
          // console.log('res', res)
        })
    },
  },
}
</script>

<style></style>
